//import { Route, Routes } from "react-router-dom";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";

import NotFound from "./components/NotFound";
import Home from "./pages/Home";

import Error from "./components/Error";
import StudentIndex from "./pages/student/StudentIndex";
import StudentDashboard from "./pages/student/StudentDashboard";
import StudentCourses from "./pages/student/StudentCourses";
import StudentPerformance from "./pages/student/StudentPerformance";
import StudentCalendar from "./pages/student/StudentCalendar";
import StudentMessages from "./pages/student/StudentMessages";

import CoursePage from "./pages/CourseComponents/CoursePage";
import AdminIndex from "./pages/Admin/AdminIndex";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import AdminStudents from "./pages/Admin/AdminStudents";
import AdminCourses from "./pages/Admin/AdminCourses";


export default function App(){

  const router = createBrowserRouter([
    { path: '/', element: <Home /> },
    { path: '*', element: <NotFound /> },
  
    //{ path: '/student-login', element: <StudentLogin saveToken={saveToken} />},
  
    { path: '/s/', 
      element:  <StudentIndex /> ,
      children: [
          {index: true, element: <StudentDashboard /> },
          { path:'courses', element: <StudentCourses /> },
          { path:'courses/:code', element: <CoursePage /> },
          { path: 'performance', element: <StudentPerformance />},
          { path: 'calendar', element: <StudentCalendar />},
          { path: 'messages', element: <StudentMessages />},
      ], 
      errorElement: <Error />,
    },

    {
      path: '/admin/',
      element: <AdminIndex />,
      children: [
        {index: true, element: <AdminDashboard /> },
        { path:'students', element: <AdminStudents />},
        { path:'courses', element: <AdminCourses /> },
      ]
    }

  ])


  return (
    <>
      <RouterProvider router={router} />
    </>
  )
};
