import CourseCard from "./CourseCard"

export default function CourseListCards(props) {

    return (
        <>
        
        <div className="row">
            {
                props.courses.map((course) => {
                    return <CourseCard key={course.code} code={course.code} url={course.url}
                        name={course.name} thumbnail={course.thumbnail} />
                })
            }
        </div>
        

        </>
    )
}