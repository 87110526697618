import { Link } from "react-router-dom"

export default function ProfilerDropdown(props){
    return (
        <div className={"dropdown " + props.className}>
                <Link to="#" className="d-flex align-items-center text-secondary dropdown-toggle"  id="dropdownUser1"
                 data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="false">
                    <img src={props.profiler.dpUrl} alt="" width="32" height="32" className="rounded-circle me-2" />
                </Link>
                <ul className="dropdown-menu dropdown-menu-light text-small shadow" aria-labelledby="dropdownUser1">
                    <li><Link className="dropdown-item" to="#"><i className="bi bi-gear-fill"></i> Settings</Link></li>
                    <li><Link className="dropdown-item" to="#"><i className="bi bi-person-fill"></i> Profile</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><button className="dropdown-item" onClick={props.profiler.logout}><i className="bi bi-box-arrow-right"></i> Sign out </button></li>
                </ul>
        </div>
    )
}