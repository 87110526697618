import { useEffect, useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";

import { API_BASE_URL } from "../../APIs/APIConfig";
import { decryptData, encryptData } from "../../utils/EncryptionUtils";

import CourseVideo from "./CourseVideo";
import CourseTopbar from "./CourseTopbar";
import CourseContents from "./CourseContents";



export default function CoursePage() {
    const { code } = useParams()
    const [student, setStudent, token] = useOutletContext()
    if(false){
        setStudent()
    }

    const sCourse = student.program.courses.filter((course) => course.code === code && course)[0]
    //const sCourseIndex = student.program.courses.findIndex(course => course.code === code && course)
    //console.log(sCourseIndex)

    const [lectureStatus, setLectureStatus] = useState(sCourse.lectureStatus);
    const [lectures, setLectures] = useState([]);


    const navs = [
        { id: 1, linkName: 'All Courses', link: '/s/courses' },
        { id: 2, linkName: sCourse.name, link: `/s/courses/${code}` }
    ]

    const [courseFetched, setCourseFetched] = useState(false);

    const courseFetcher = async () => {
        const res = await fetch(`${API_BASE_URL}/courses/${code}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })

        const eData = await res.json()
        const course = decryptData(eData['enc'])

        setLectures(course.lectures)
        setCourseFetched(true);
    };


    const [videoUrl, setVideoUrl] = useState(null);
    const [videoOrder, setVideoOrder] = useState(1)
    const [videoDone, setVideoDone] = useState(false)
    
    const [isCompleted, setIsCompleted] = useState(false)

    
    const videoLoader = async (code, order) => {
        /*const res = await fetch(`${API_BASE_URL}/videoUrl/${code}/${order}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })

        const eData = await res.json()
        const data = decryptData(eData['enc'])

        if (data['videoUrl']) {
            setVideoUrl(data['videoUrl'])
        }*/

        setVideoUrl(lectures.filter((lec)=> lec.order===order)[0].url)

        setIsCompleted(() => {
            for (let lec of lectureStatus) {
                if (lec.order === videoOrder) {
                    return lec.isCompleted
                }
            }
        })
    }

    const videoSaver = async (code, order) => {
        const res = await fetch(`${API_BASE_URL}/videoUrl/${code}/${order}`,
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(
                {
                    enc: encryptData({ 'student_id': student.id, 'isCompleted': true })
                }),
            })

        const eData = await res.json()
        const data = decryptData(eData['enc'])
        if (data['message'] === 'success') {
            setIsCompleted(true)

            setLectureStatus((prevState) => {
                return [...prevState, {isCompleted: true, order: order} ]
            })
        }

    }

    useEffect(() => {

        if (!courseFetched) {
            courseFetcher()
        }
        
        if (!videoUrl) {
            videoLoader(code, videoOrder)
        }

        if (videoDone && !isCompleted) {
            videoSaver(code, videoOrder)
        }
    })


    return (
        <div>
            <CourseTopbar navs={navs} 
            info={()=>{
                const completed = (sCourse.lectureStatus.filter((lec)=> lec.isCompleted) ).length
                const total = lectures.length
                const progress = completed/total * 100
                return [completed, total, progress]
            }} />
            <div className="d-none d-md-block p-2" ></div>
            <div className="row px-3">
                <div className="col-xs-12 col-md-12 col-lg-8 bg-dark p-0">
                    <CourseVideo videoUrl={videoUrl}  setVideoDone={setVideoDone} />
                </div>

                <div className="col-xs-12 col-md-12 col-lg-4 p-0" >
                    <div className="rounded bg-white border shadow h-100">
                        <div className="text-center fs-5 text-secondary p-3 border">
                            <i className="bi bi-bookmarks-fill"></i> Course Contents
                        </div>
                        <div style={{ height: '400px', overflowY: 'scroll' }}>
                            <CourseContents code={code} lectures={lectures} lectureStatus={lectureStatus}
                                videoLoader={videoLoader} setVideoOrder={setVideoOrder} videoOrder={videoOrder} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}