import ProfilerDropdown from "./ProfilerDropdown";

export default function TopbarMain(props){
    return (
        <header className="d-flex mb-3 bg-white border-bottom px-3">
            <button className="btn border rounded m-1 d-block d-md-none d-lg-none d-xl-none" type="button"
                    data-bs-toggle="offcanvas" data-bs-target="#sidebar" data-canvas="body"
                    aria-expanded="false" aria-controls="sidebar">
                    <i className="bi bi-list fs-3"></i>
            </button>

            <div className="flex-fill p-2 px-5 fs-4 fw-bold text-secondary">Welcome {props.studentTitle}{props.studentName}</div>

            <div className="flex-fill p-2  w-50 d-none d-lg-block">
                <form className=" d-flex form-group">
                    <input className="form-control" placeholder="Search..."/>
                    <button type="submit" className="btn">
                        <i className="bi bi-search"></i>
                    </button>
                </form>
            </div>

            <div className="flex-fill p-2  d-none d-lg-block">
                <i className="bi bi-bell-fill fs-4 text-secondary" ></i>
            </div>
            
            <div className=" p-2 ms-auto">
                <ProfilerDropdown profiler={props.profiler} />
            </div>
        </header>
    )
}