import { lms_image, potrait, ris_logo_title } from '../assets/MediaImports';
import "../components/UI/styles.css";
import Copyright from '../components/Copyright';

import { Link } from 'react-router-dom';

export default function Home() {
    return (
        <>
        <div className='front-bg'>
            <div className="container py-4 px-2">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="container text-center h-100" >
                            <div className='mt-4'>
                                <p className='display-4 text-wrap'>
                                    <small>Welcome To</small><br />
                                    <b><span style={{ fontFamily: "lobster" }}>TunEd </span> LMS</b></p>
                                <p className='text-secondary'>
                                    We enable educators an effecient way of online teaching with
                                    powerful digital interactive tools that will accelerate student's
                                    learning skills.
                                </p>
                                <Link to="/s/" className=" btn btn-primary p-2 rounded-5 my-3 w-100" >Student</Link>
                                <Link to="/admin/" className="btn btn-primary  p-2 rounded-5 w-100" >Admin</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="container" >
                            <img src={lms_image} alt="lms" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container p-2 mt-3'>
                <div className='row'>
                    <div className="col">
                        <div className='container bg-light p-3' style={{ maxWidth: "500px" }}>
                            <img src={ris_logo_title} alt="lms" className='mx-auto d-block border rounded img-fluid shadow' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='container p-5'>
                <div className='row ' >
                    <div className='col-lg-3 col-md-12'>
                        <div style={{ width: '250px', margin: 'auto' }}>
                            <div className='fs-3'>
                                <div>
                                    <i><span className='display-6 fw-bold'> Word</span> from</i>
                                </div>
                                <div className='text-end'>
                                    <i>our <span className='display-6 fw-bold'> Director</span></i>
                                </div>
                            </div>
                        </div>

                        <div className='card mt-5 text-center shadow p-2'>
                            <img src={potrait} className='card-img-top' alt='director' />
                            <div className="card-body">
                                <h5 className="card-title">O Rajesh</h5>
                                <p className="card-text"> Director - Raj Info Solutions</p>

                            </div>
                        </div>
                    </div>
                    <div className='col-lg-1'></div>
                    <div className='col'>
                        <div className='fs-5 py-4'>
                            <i>
                                <p style={{ textIndent: "50px", textAlign: 'justify' }}><i className='bi bi-quote fs-1'></i>
                                    <b>Raj Info Solutions</b> is a leading educational institution dedicated to providing
                                    comprehensive computer training and IT solutions. With a passion for empowering
                                    individuals with digital skills, we offer a wide range of courses that cater to
                                    beginners and experienced professionals alike.
                                </p>
                                <p style={{ textIndent: "50px", textAlign: 'justify' }}>
                                    Our state-of-the-art facilities and experienced instructors ensure a conducive
                                    learning environment, enabling our students to grasp cutting-edge technologies and
                                    industry-relevant knowledge. Whether you're looking to enhance your proficiency
                                    in programming, web development, graphic design, or other IT domains, we have
                                    the perfect course to fit your needs.
                                </p>
                                <p style={{ textIndent: "50px", textAlign: 'justify' }}>
                                    At Raj Info Solutions, we believe in fostering a culture of continuous learning,
                                    creativity, and innovation. Our mission is to equip our students with the expertise
                                    to excel in today's competitive digital landscape and to prepare them for the
                                    challenges of tomorrow.
                                </p>
                                <p style={{ textIndent: "50px", textAlign: 'justify' }} className='fw-bold'>
                                    - Join us on this transformative journey and unlock your potential.
                                </p>
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Copyright />
        </>
    );
}