import { useOutletContext } from "react-router-dom"
import CustomTable from '../../../components/CustomTable'
import { useEffect, useState } from "react"
import { decryptData } from "../../../utils/EncryptionUtils"
import { API_BASE_URL } from "../../../APIs/APIConfig"

export default function ListStudents(){
    const [admin, token] = useOutletContext()
    if(false){
        console.log(admin)
    }

    const [students, setStudents] = useState([])

    useEffect(()=>{
        const studentFetcher = async () => {
            const res = await fetch(`${API_BASE_URL}/student`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type':'application/json'
                    },
                })
            
            const eData = await res.json()
            const data = decryptData(eData['enc'])
            
            setStudents(()=>{
                let stus = data.map((stu)=>{
                    return {
                        Id : stu['id'],
                        FirstName : stu['firstName'],
                        LastName : stu['lastName'],
                        Email : stu['email'],
                        Phone : stu['phone']
                    }
                })
                return stus
            })
        }
        if(students.length===0){
            studentFetcher()
        }
    })



    return <CustomTable records={students} title='Students Records' recordsPerPage={10}/>
}