import { Link } from "react-router-dom"

export default function CourseCard(props) {
    return (
        <>
            <div className="col-xs-12 col-md-6 col-lg-4">
                <div className="card m-1 shadow" style={{ minWidth: '200px' }}>
                    <Link to={props.url} className="text-decoration-none">
                        <img className="card-img-top w-100 h-100" src={props.thumbnail} alt={props.name + ' Thumbnail'} />
                        <div className="card-body">
                            <h6 className="text-secondary">{props.code} </h6>
                            <p className="card-title fs-5 text-secondary fw-bold">{props.name}</p>
                        </div>
                    </Link>
                </div>
            </div>

        </>
    );
}