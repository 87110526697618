import { useState } from "react"
import Tabs from "../../components/Tabs"
import AddStudents from "./components/AddStudents"
import DeleteStudents from "./components/DeleteStudents"
import ListStudents from "./components/ListStudents"


export default function AdminStudents() {
    const tabs = ['List', 'Add', 'Delete']

    const [display, setDisplay] = useState(<ListStudents />)

    const setOp = (op)=>{

        if(op==='List'){
            setDisplay(<ListStudents />)
        }
        if(op==='Add'){
            setDisplay(<AddStudents />)
        }  
        if(op==='Delete'){
            setDisplay(<DeleteStudents />)
        }  
    }

    return (
        <>
            <Tabs list={tabs} setOp={setOp} />
            <div className="container">
                {display}
            </div>
        </>
    )
}