import { admin_toon } from "../../assets/MediaImports";

export default function AdminLoginForm(props) {
    return (
        <div className="container py-4" style={{ maxWidth: "50rem" }}>
            <div className="border rounded-4 shadow" >
                <div className="row">
                    <div className="col d-none d-md-block">
                        <img src={admin_toon} alt="admin toon" className="img-fluid rounded-4 mt-5"  />
                    </div>
                    <div className="col">
                        <div className="p-3 text-dark h-100" >
                            
                            <form onSubmit={props.form.submitHandler}>
                                <div className="display-5 fw-bold text-center pb-5">
                                    Admin Login </div>

                                <div className="mb-4 w-100" >
                                    <input type="email " className='form-control ' placeholder="Email"
                                        onChange={(event) => { props.form.setEmail(event.target.value) }} value={props.form.email} />

                                    <div className="">
                                        <small style={{ color: "red" }}>
                                            {!props.form.emailValid ? '*Enter valid email' : <>&nbsp;</>}
                                        </small>
                                    </div>
                                </div>

                                <div className="mb-4 w-100" >
                                    <input type="password" className='form-control ' placeholder="Password"
                                        onChange={(event) => { props.form.setPassword(event.target.value) }} value={props.form.password} />

                                    <div className="">
                                        <small style={{ color: "red" }}>
                                            {!props.form.passwordValid ? '*Enter valid password' : <>&nbsp;</>}
                                        </small>
                                    </div>
                                </div>
                                   {/*
                                    <div className="text-end" ><a className="small text-muted" href="#!">Forgot password ?</a></div>   
                                   */} 
                                

                                <div className="mt-4 w-100" >
                                    <button className="btn btn-primary w-100 rounded-5" type="submit">
                                        Continue <i className="bi bi-box-arrow-in-right"></i></button>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}