import { useOutletContext } from "react-router-dom";

import CourseListCards from '../CourseComponents/CourseListCards'

export default function StudentCourses(){

    const [student] = useOutletContext()

    const sCourses = student.program.courses.map((course)=>{

        return {
            code: course.code,
            name: course.name,
            thumbnail: course.thumbnail,
            url: course.code
        }
    })

    return (
        <>
        <div className="w-100">
            <div className="card bg-white border rounded p-3" >
                <div className="text-secondary fw-bold fs-4">
                    <i className="bi bi-card-checklist"></i> Courses Enrolled</div>
                <hr/>
                <CourseListCards courses={sCourses} />
            </div>
        </div>
    </>
    )
}