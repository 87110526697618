import { Outlet } from "react-router-dom";

import TopbarMain from "../../components/TopbarMain";
import Sidebar from "../../components/Sidebar"

export default function AdminHome(props) {

    const menuItems = [
        { id: 1, name: "Dashboard", link: "/admin/", icon: "house-door" },
        { id: 2, name: "Manage Students", link: "students", icon: "people-fill" },
        { id:3, name:"Manage Courses", link:"courses", icon:"journals" },
        //{ id:4, name:"Calendar", link:"calendar", icon:"calendar3" },
        //{ id:5, name:"Messages", link:"messages", icon:"envelope" },
    ]

    const profiler={logout : props.logout,}// dpUrl: props.student.profile.dpUrl}

    return (
        <div className="d-flex">
            <div id="sidebar" className="offcanvas-md flex-shrink-0" style={{width:"250px"}}>
                <Sidebar menuItems={menuItems}  profiler={profiler} bg="bg-theme-admin"/>
            </div>
            <div className="flex-fill">
                <TopbarMain profiler={profiler} />
                <div className="container">
                    <Outlet context={[props.Admin, props.token]} />
                </div>
                
            </div>
        </div>
    )
}