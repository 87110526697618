import { Link, NavLink} from "react-router-dom";

import "./UI/styles.css"
import './Sidebar.css'
import { ris_logo, ris_title } from "../assets/MediaImports";

export default function Sidebar(props) {
    return (
        <div className={"d-flex flex-column flex-shrink-0 p-3 text-white h-100 min-vh-100 " + props.bg}>
            <Link to="/" className="text-center text-white text-decoration-none">
                <span className="fs-2 app_title" >TunEd </span>
            </Link> 
            <hr />
            <ul className="nav nav-pills flex-column mb-auto">
                {
                    props.menuItems.map((item) => {
                        return <li className="nav-item" key={item.id} >
                            <NavLink to={item.link} className={({ isActive }) =>
                                isActive ? 'py-3 nav-link active' : 'py-3 nav-link'} >
                                <i className={`bi bi-${item.icon}`}></i> {item.name}
                            </NavLink>
                        </li>
                    })
                }
            </ul>
            <img src={ris_logo} alt='ris logo' className="w-50 mx-auto" />
            <img src={ris_title} alt='ris logo' className="w-100 mx-auto rounded shadow" />
        </div>
    );
}