import { useState } from "react";

export default function useUploader() {

    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [uploadDone, setUploadDone] = useState(false)
    const [media, setMedia] = useState()


    const uploadChunk = async (url, token, chunk, chunkname) => {

        const formData = new FormData();
        formData.append("file", chunk, chunkname);

        const response = await fetch(url,
            {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
                body: formData,
            }
        );

        if (!response.ok) {
            throw new Error("Error uploading chunk");
        }
    };

    const handleUpload = async (url, token) => {
        if (!media) return;
        
        const CHUNK_SIZE = 1 * 1024 * 1024; // 1MB
        const totalChunks = Math.ceil(media.size / CHUNK_SIZE);
        const chunkArray = Array.from({ length: totalChunks }, (_, index) => index);

        try {
            setUploading(true);

            for (let currentChunk of chunkArray) {
                const start = currentChunk * CHUNK_SIZE;
                const end = Math.min(media.size, start + CHUNK_SIZE);
                const chunk = media.slice(start, end);
                const chunkname = `${media.name}#*chunk${currentChunk + 1}`;

                const params = new URLSearchParams();
                params.append("currentChunk", currentChunk);
                params.append("totalChunks", totalChunks);

                await uploadChunk(url + `?${params.toString()}`, token, chunk, chunkname);

                setProgress(((currentChunk + 1) / totalChunks) * 100);
            }

            setUploadDone(true)
            alert("File uploaded successfully!");

        } catch (error) {
            console.error("Upload error:", error);
            alert("Upload failed. Please try again.");
        } finally {
            setUploading(false);
            setProgress(0);
        }
    };

    return {
        media, setMedia,
        uploadDone, setUploadDone,
        uploading, setUploading,
        progress, setProgress,
        handleUpload
    }
}