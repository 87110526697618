import { useState } from "react"
import {API_BASE_URL} from '../../../APIs/APIConfig'
import useUploader from "../../../hooks/useUploader"

export default function FormCourseLecture({ courseCode, order, lectureData, setLectureData, onDelete, token }) {

    const star = <span style={{ color: "red" }}>*</span>

    const {media, setMedia, uploadDone, setUploadDone, uploading, progress, handleUpload} = useUploader()
    const [lectureName, setLectureName] = useState()
    const [lectureLen, setLectureLen] = useState()

    const handleVideoChange = (event) => {
        const file = event.target.files[0];

        if (file && file.type.startsWith('video/')) {
            setUploadDone(false)
            setMedia(file);
        }
        else {
            setMedia('')
        }
    }

    const handleNameChange = (event) => {
        setLectureName(event.target.value)
        const newData = { ...lectureData, name: event.target.value, order:order };
        setLectureData(newData);
    }
    const handleLenChange = (event) => {
        setLectureLen(event.target.value)
        const newData = { ...lectureData, duration: event.target.value + ' min' };
        setLectureData(newData);
    }


    const videoUploadHandler = ()=>{
      const url = `${API_BASE_URL}/upload/video/${courseCode}/${order}`
      handleUpload(url, token)
      
        setLectureData({
          ...lectureData,
          blob_name: `${courseCode}/${media.name}`
        })
      
    }

    return (
        <>
            <div className="d-flex mb-4">
                <div className="me-3 mb-auto">
                    <span className=" badge bg-primary">{order}</span>
                </div>
                <div className="flex-fill p-1">
                    <label className="form-label">{star} Lecture Name</label>
                    <input placeholder="Enter Lecture Name" className="form-control" required value={lectureName} onChange={handleNameChange} />
                </div>
                <div className="flex-fill p-1">
                    <label className="form-label">{star} Lecture Video 
                    {media && <span> | {media.type} - {Math.ceil(media.size / 1024 / 1024)}MB</span>} 
                    {uploading && <span> | <progress value={progress} max="100" /></span>}</label>
                    {uploadDone && <span> | Upload done..!</span>}
                    <input type="file" className="form-control" required accept="video/*" onChange={handleVideoChange} />
                    
                </div>
                <div className="mx-2 mt-auto">
                    <button className="btn btn-warning" type="button" onClick={videoUploadHandler} disabled={uploading}>
                        {uploading ? "..." : <i className="bi bi-upload"></i>}
                    </button>
                </div>
                <div className="flex-fill p-1">
                    <label className="form-label">{star} Lecture Duration</label>
                    <input placeholder="Enter Lecture Duration in min" type="number" className="form-control" required value={lectureLen} onChange={handleLenChange} />
                </div>
                <div className="mx-2 mt-auto">
                    <button className="btn btn-danger" type="button" onClick={onDelete}><i className="bi bi-trash-fill"></i></button>
                </div>
            </div>
        </>
    )
}