export default function Copyright(){
    return (
        <>
            <hr className="mt-0 p-0"/>
            <div className="container-fluid text-center text-secondary">
                <sup>Site design © 2023 TunEd, Powered by CompuGit. All Rights Reserved.</sup>
            </div>
        </>

    );
}