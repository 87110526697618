import { useEffect, useState } from "react";
import useToken from "../../hooks/useToken";

import { API_BASE_URL } from "../../APIs/APIConfig";


import AdminHome from "./AdminHome";
import AdminLogin from "./AdminLogin";
import LoadingCircle from "../../components/LoadingCircle";

import { encryptData, decryptData } from "../../utils/EncryptionUtils";


export default function AdminIndex() {

    const { token, saveToken, removeToken, isSessioned, setIsSessioned } = useToken();

    const [Admin, setAdmin] = useState(null);

    const login = async (formData) => {

        const res = await fetch(API_BASE_URL + '/api/token',
            {
                method: 'POST',
                headers: {
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(
                    {
                        enc: encryptData(formData)
                    }
                ),
            })
        
        const eData = await res.json()
        const data = decryptData(eData['enc'])

        if (data['message'] === 'success'){
            saveToken(eData['access_token'])
        }
        else{
            alert(' Error : ' + data.error)
        }

    }

    const logout = async () => {

        await fetch(API_BASE_URL + '/api/logout',
        {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type':'application/json'
            },
        }).then((res) => {
            if (res.status === 200) {
                removeToken()
                setAdmin(null)
            }
        })
    }

    useEffect( ()=>{
        if(token){
            setIsSessioned(true)
        }
        if(isSessioned && !Admin){
            const AdminFetcher = async () => {
                const res = await fetch(`${API_BASE_URL}/admin_index`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type':'application/json'
                        },
                    })
                
                const eData = await res.json()
                const data = decryptData(eData['enc'])
                
                return data
                
            }
            const fetchAdminData = async () => {
                const fetchedAdmin = await AdminFetcher();
                setAdmin(fetchedAdmin);
              };
          
              fetchAdminData();
        }
    }, [Admin, setAdmin, token, saveToken, isSessioned, setIsSessioned ])


    return (
        <>
        {isSessioned && Admin && <AdminHome Admin={Admin} logout={logout} token={token} saveToken={saveToken}/>}
        {isSessioned && !Admin && <div className='w-100 h-100'><LoadingCircle /></div>}
        {!isSessioned && <AdminLogin login={login} />}
        </>
    )
            
}