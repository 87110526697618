import { useOutletContext } from "react-router-dom";
import FormCourse from "./FormCourse";
import { useState } from "react";
import { API_BASE_URL } from "../../../APIs/APIConfig";
import { decryptData, encryptData } from "../../../utils/EncryptionUtils";
import { courseThumbnail } from "../../../assets/MediaImports";

export default function AddCourses(){

    const [admin, token] = useOutletContext()
    if(false){
        console.log(admin)
    }

    const formTemplate = {
        materialStatus: true,
        lectures:[{}],
        thumbnail:courseThumbnail
    }

    const [formData, setFormData] = useState(formTemplate)

    const onSubmit = (event)=>{
        event.preventDefault();
        const register = async () => {

            const res = await fetch(API_BASE_URL + '/courses/new',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type':'application/json'
                    },
                    body: JSON.stringify(
                        {
                            enc: encryptData(formData)
                        }
                    ),
                })
            
            const eData = await res.json()
            const data = decryptData(eData['enc'])
    
            if (data['message'] === 'success'){
                alert('New Course Added successfully')
                setFormData(formTemplate)
                document.location.reload()
            }
            else{
                alert(' Error : ' + data.error)
            }
        }; register()
    }

    return <FormCourse submitHandler={onSubmit} formData={formData} setFormData={setFormData} token={token}/>
}