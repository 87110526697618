import oops_img from '../assets/oops.png'

export default function NotFound(){
    return (
        <>
            <div className='container my-5 p-5' style={{margin:"auto auto", maxWidth:"40rem"}}>
                <img src={oops_img} alt='notfound' style={{width:"100%"}}/>
            </div>
        </>
    )
}