import BreadcrumbNav from "../../components/BreadcrumbNav";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { DEEP_SKY_BLUE, LIGHT_BLACK } from "../../components/UI/colors";


export default function CourseTopbar(props){
    const [completed, total, progress] = props.info()
    return (
        <div className="d-flex justify-content-between rounded bg-white border shadow">
                <div className="flex-grow-1 mt-2 py-2 d-none d-lg-block">
                    <BreadcrumbNav navs={props.navs} />
                </div>

                <div className="mx-auto mt-2 px-3 py-1">

                    <CircularProgress value={progress} color={`${DEEP_SKY_BLUE}`} trackColor="#ddd"
                        size="40px" thickness='8px' capIsRound transition="all 0.5s ease-out"
                        style={{fontSize:'10px', color:LIGHT_BLACK}}>
                        <CircularProgressLabel > <i className="bi bi-trophy-fill fs-6 text-secondary"> </i> </CircularProgressLabel>
                    </CircularProgress>

                    &nbsp; {completed} out of {total} lectrues completed
                </div>
            </div>
    )
}