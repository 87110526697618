import { useEffect, useState } from "react";
import { SHA256 } from "crypto-js";

import FormStudent from "./FormStudent";
import { decryptData, encryptData } from "../../../utils/EncryptionUtils";
import { API_BASE_URL } from "../../../APIs/APIConfig";
import { useOutletContext } from "react-router-dom";
import { userDP } from "../../../assets/MediaImports";

export default function AddStudents(){

    const [admin, token] = useOutletContext()
    if(false){
        console.log(admin)
    }

    const formTemplate = {
        profile:{
            address:{
                city:'Machilipatnam',
                country:'India',
                state:'Andhra Pradesh',
                pinCode:521002
            },
            gender:'Male',
            title:'Mr.',
            dp: userDP
        },
        program:{
            type:'Regular',
            courses: []
        }
    }

    const [formData, setFormData] = useState(formTemplate)

    const [courses, setCourses] = useState([])
    const [coursesFetched, setCoursesFetched] = useState(false)

    useEffect(()=>{
        const coursesFetcher = async () => {
            const res = await fetch(`${API_BASE_URL}/courses`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type':'application/json'
                    },
                })
            
            const eData = await res.json()
            const data = decryptData(eData['enc'])
            
            setCourses(()=>{
                let cs = data.map((c)=>{
                    return {
                        code : c['code'],
                        name : c['name'],
                        thumbnail: c['thumbnail']
                    }
                })
                return cs
            })
            setCoursesFetched(true)
        }
        if(!coursesFetched){
            coursesFetcher()
        }
    })

    const onSubmit = (event)=>{
        event.preventDefault();
        const register = async () => {

            const res = await fetch(API_BASE_URL + '/student',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type':'application/json'
                    },
                    body: JSON.stringify(
                        {
                            enc: encryptData({
                                ...formData,
                                password: SHA256(formData.phone).toString(),
                            })
                        }
                    ),
                })
            
            const eData = await res.json()
            const data = decryptData(eData['enc'])
    
            if (data['message'] === 'success'){
                alert('Student Registration successful')
                setFormData(formTemplate)
                document.location.reload()
            }
            else{
                alert(' Error : ' + data.error)
            }
        }; register()
    }

    return <FormStudent submitHandler={onSubmit} formData={formData} setFormData={setFormData} courses={courses}/>
}