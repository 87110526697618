import CryptoJS from 'crypto-js';

const KEY = 'ba1e8507e4dfbe1e60e5ea7f4f6328f6'; // Replace with your encryption key

const generateRandomIV = () => {
  // Function to generate a random IV
  return CryptoJS.lib.WordArray.random(16); // 16 bytes (128 bits) for AES block size
};

const encryptData = (data) => {
  const dataStr = JSON.stringify(data);
  const iv = generateRandomIV(); // Generate a random IV
  const encrypted = CryptoJS.AES.encrypt(dataStr, CryptoJS.enc.Utf8.parse(KEY), {
    iv: iv,
    mode: CryptoJS.mode.CBC, // Use CBC mode
    padding: CryptoJS.pad.Pkcs7, // Use PKCS7 padding
  });
  const ciphertextWithIV = iv.concat(encrypted.ciphertext); // Combine IV with ciphertext
  return ciphertextWithIV.toString(CryptoJS.enc.Base64);
};

const decryptData = (encryptedData) => {
  const ciphertextWithIV = CryptoJS.enc.Base64.parse(encryptedData);
  const iv = CryptoJS.lib.WordArray.create(ciphertextWithIV.words.slice(0, 4)); // Extract IV (first 16 bytes)
  const ciphertext = CryptoJS.lib.WordArray.create(ciphertextWithIV.words.slice(4)); // Extract the remaining ciphertext
  const decrypted = CryptoJS.AES.decrypt(
    {
        ciphertext: ciphertext,
    },
    CryptoJS.enc.Utf8.parse(KEY),
    {
      iv: iv,
      mode: CryptoJS.mode.CBC, // Use CBC mode
      padding: CryptoJS.pad.Pkcs7, // Use PKCS7 padding
    }
  );
  
  const decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
};

export { encryptData, decryptData };
