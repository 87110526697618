import { useOutletContext } from "react-router-dom";

import ProgressBarRound from "../../components/ProgresBarRound";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../APIs/APIConfig";
import { decryptData, encryptData } from "../../utils/EncryptionUtils";

export default function StudentDashboard() {
    const [student, setStudent, token] = useOutletContext()
    if(false){
        setStudent()
    }

    const sCourses = student.program.courses
    const [allCourses, setAllCourses] = useState([])
    const [coursesFetched, setCoursesFetched] = useState(false)


    const [aMark, setAMark] = useState(null)
    const [att, setAtt] = useState(null)
    
    const attendance_marker = async (mark) => {
        const res = await fetch(API_BASE_URL  + `/student_attendance/${student.id}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    enc: encryptData({ 'mark': mark })
                }),
        })
        if (res.ok) {
            setAtt(mark)
            setAMark(true)
        }
    }

    useEffect(()=>{
        const attendance_fetcher = async () => {
            const res = await fetch(API_BASE_URL + `/student_attendance?id=${student.id}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            if (res.ok) {
                const eData = await res.json()
                const data = decryptData(eData['enc'])
                setAtt(true && data['mark'])
                setAMark(true && data['mark'] && data['mark'])
            }
        }

        if(!aMark){
            attendance_fetcher()
        }

        const coursesFetcher = async () => {
            const res = await fetch(`${API_BASE_URL}/courses`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json'
                    }
                })
    
            const eData = await res.json()
            const courses = decryptData(eData['enc'])
            setAllCourses(courses)
            setCoursesFetched(true)
        };
        if(!coursesFetched){
            coursesFetcher();
        }
       
    },[aMark, student, token, coursesFetched])

    return (

        <div className="row" style={{  }}>
            <div className="col-lg-8">
                <div className="card shadow bg-white w-100 p-3 text-secondary text-center">
                    <h4 className="text-start">
                        <i className="bi bi-pie-chart-fill"></i> Courses Progress</h4>
                    <hr />

                    <div className="d-flex justify-content-center">
                        {
                            sCourses.map((course) => {
                                const completed = (course.lectureStatus.filter((lec) => lec.isCompleted)).length
                                const c = allCourses.find(x => x.code===course.code)
                                const total = c===undefined ? 0 : c.lectures.length
                                const progress = completed / total * 100

                                return <div className="mx-4" key={course.code}>
                                    <ProgressBarRound value={progress} delay="500" />
                                    <h6>{course.name}</h6>
                                </div>
                            })
                        }

                    </div>
                </div>

                <div className="card shadow bg-white w-100 p-3 text-secondary text-center mt-3">
                    <h4 className="text-start"><i className="bi bi-exclamation-square-fill"></i> What's Due</h4>
                    <hr />
                    <div>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Course Name</th>
                                    <th>Classes</th>
                                    <th>Join Date</th>
                                    <th>End Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sCourses.map((course) => {
                                    const completed = course.lectureStatus.filter((lec) => lec.isCompleted)
                                    const c = allCourses.find(x => x.code===course.code)
                                    const total = c===undefined ? 0 : c.lectures.length
                                    const joinDate = new Date(course.subscription.joinDate)
                                    const endDate = new Date(course.subscription.endDate)

                                    return <tr key={course.code}>
                                        <td> <i className="bi bi-stopwatch"></i> {course.name} </td>
                                        <td>{completed.length} / {total} </td>
                                        <td>{joinDate.toLocaleDateString()}</td>
                                        <td>{endDate.toLocaleDateString()}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div className="col-lg-4 ">
                <div className="d-block d-lg-none p-2" ></div>
                <div className="card shadow p-3 bg-white">
                    {!aMark ? <>
                        <div className="text-dark fs-6"> Mark today's Attendence. </div>
                        <div className="d-flex">
                            <div className="flex-fill p-1">
                                <button className="btn btn-success w-100" onClick={() => attendance_marker('P')}>
                                    <i className="bi bi-check-lg"></i>
                                </button>
                            </div>
                            <div className="flex-fill p-1">
                                <button className="btn btn-danger w-100" onClick={() => attendance_marker('A')}>
                                    <i className="bi bi-x-lg"></i>
                                </button>
                            </div>
                        </div>
                    </> :
                        <div className="py-4">
                            <div className="text-dark fs-6 text-center"> Today's Attendence. 
                                <span className={`badge ${att==='P' ? 'bg-success' : 'bg-danger'}`}>{att}</span> 
                            </div>
                        </div>
                    }
                </div>
                
                <div className="card shadow bg-white w-100 p-3 mt-3 mb-3">
                    <ul className="list-group list-group-flush ">
                        <li className="list-group-item text-center fs-5 text-secondary">
                            <i className="bi bi-pin-angle-fill"></i> Notice board</li>
                        <li className="list-group-item mt-1 p-3">No items currently</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}