import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";


export default function BreadcrumbNav(props){
    return (
        <div>
            <Breadcrumb spacing='16px' separator='>' className="text-secondary">
                {
                    props.navs.map( (item)=>{
                        return (
                            <BreadcrumbItem key={item.id}>
                                <BreadcrumbLink href={item.link} className="text-decoration-none text-dark">{item.linkName}</BreadcrumbLink>
                            </BreadcrumbItem>
                        )
                    } )
                }
                
            </Breadcrumb>
        </div>
    )
}