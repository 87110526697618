import { Link } from "react-router-dom"

import "../components/UI/styles.css"


export default function TopbarTitle(props){
    return (
        <>
            <div className={"container-fluid " + props.bg} >
                <div className="p-1 text-start display-6">
                    <Link to="/" className="text-decoration-none text-white">
                    <span className="app_title">TunEd </span> LMS
                    </Link>
                </div>
            </div>
        </>
    )
}