import { useState } from "react"

export default function Tabs({ list, setOp }) {
    const [active, setActive] = useState(list[0])

    return (
        <ul className="nav nav-tabs">
            {
                list.map((tab, index) => {
                    return <li className="nav-item" key={index}>
                        <button className={`nav-link ${active === tab ? 'active' : ''}`} 
                        onClick={() => { setActive(tab); setOp(tab) }}>{tab}</button>
                    </li>
                })
            }
        </ul>
    )
}