import { useEffect, useState } from 'react'
import Attendance from './components/Attendance'
import { API_BASE_URL } from '../../APIs/APIConfig'
import { useOutletContext } from 'react-router-dom'
import { decryptData } from '../../utils/EncryptionUtils'

export default function AdminDashboard() {

    const token = useOutletContext()[1]
    const [attendanceRecords, setAttendanceRecords] = useState([])
    const [attendanceFetched, setAttendanceFetched] = useState(false)

    useEffect( ()=>{

        const attendance_fetcher = async () => {
            const res = await fetch(API_BASE_URL + `/student_attendance`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                }
            })
            if (res.ok) {
                const eData = await res.json()
                const data = decryptData(eData['enc'])
    
                setAttendanceRecords(data)
                setAttendanceFetched(true)
            }
        }
        if(!attendanceFetched){
            attendance_fetcher()
        }
    })
    


    return (
        <>
            <Attendance records={attendanceRecords} />
        </>
    )
}