import { useOutletContext } from "react-router-dom"
import CustomTable from '../../../components/CustomTable'
import { useEffect, useState } from "react"
import { decryptData } from "../../../utils/EncryptionUtils"
import { API_BASE_URL } from "../../../APIs/APIConfig"

export default function ListCourses(){
    const [admin, token] = useOutletContext()
    if(false){
        console.log(admin)
    }

    const [courses, setCourses] = useState([])
    const [coursesFetched, setCoursesFetched] = useState(false)

    useEffect(()=>{
        const coursesFetcher = async () => {
            const res = await fetch(`${API_BASE_URL}/courses`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type':'application/json'
                    },
                })
            
            const eData = await res.json()
            const data = decryptData(eData['enc'])
            
            setCourses(()=>{
                let cs = data.map((c)=>{
                    return {
                        Code : c['code'],
                        CourseName : c['name'],
                        Tutor: c['tutor'],
                        LecturesCount : c['lectures'].length,
                        MaterialStatus : c['mr_status'] ? 'Available' : 'Not Available',
                    }
                })
                return cs
            })

            setCoursesFetched(true)
        }
        if(!coursesFetched){
            coursesFetcher()
        }
    })



    return <CustomTable records={courses} title='Courses Records' recordsPerPage={10}/>
}