import { useEffect, useState } from 'react';
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";

import {DEEP_SKY_BLUE, LIGHT_BLACK} from './UI/colors';


export default function ProgressBarRound(props){
    const progress = props.value;
    const delay = props.delay;

    const [animatedProgress, setAnimatedProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
        setAnimatedProgress(progress);
        }, delay);

        return () => {
        clearInterval(interval);
        };
    }, [progress, delay]);

    return (
        <CircularProgress value={animatedProgress} color={`${DEEP_SKY_BLUE}`} trackColor="#ddd"
            size="100px" thickness='8px' capIsRound transition="all 0.5s ease-out"
            style={{fontSize:"60px", color:LIGHT_BLACK}}>

            <CircularProgressLabel >{Math.ceil(animatedProgress)}%</CircularProgressLabel>

        </CircularProgress>
    )
}