import { useState } from "react";
import { SHA256 } from "crypto-js";

import TopbarTitle from "../../components/TopbarTitle";
import StudentLoginForm from "./StudentLoginForm";
import { ris_logo_title } from "../../assets/MediaImports";


export default function StudentLogin(props) {

    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(true)
    const [password, setPassword] = useState('')
    const [passwordValid, setPasswordValid] = useState(true)


    function submitHandler(event) {
        event.preventDefault();

        if (email.trim() === '' || !email.includes('@')) {
            setEmailValid(false)
            return;
        }
        else {
            setEmailValid(true)
        }

        if (password.trim() === '') {
            setPasswordValid(false)
            return;
        }
        else {
            setPasswordValid(true)
        }

        let formData = { 
            email: email, 
            password: SHA256(password).toString(),
            role: 'student'
        };

        props.login(formData)
    }
    
    const form = {
        email: email,
        emailValid: emailValid,
        setEmail:setEmail,
        password: password,
        passwordValid: passwordValid,
        setPassword: setPassword,
        submitHandler: submitHandler
    }

    return (
        <>
            <TopbarTitle bg="bg-theme-r"/>
            <StudentLoginForm form={form} />
            <div className='container py-2 px-2'>
                <div className='row'>
                    <div className="col">
                        <div className='container bg-light p-3' style={{maxWidth:"500px"}}>
                            <img src={ris_logo_title} alt="lms" className='mx-auto d-block border rounded img-fluid shadow'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}