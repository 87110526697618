import '../../components/UI/styles.css'

export default function CourseContents(props) {

    return (
            <ul className="list-group list-group-flush ">
               
                {
                    props.lectures.map( (item)=>{

                        const isActive = item.order===props.videoOrder 

                        const checkStatus = () => {
                            const str = `${isActive ? 'text-white' : ''} bi text-secondary fs-4 `;
                            const lec = props.lectureStatus.find(lec => lec.order === item.order);
                            const flag = lec ? lec.isCompleted : false;
                            return flag ? str + 'bi-check-square-fill' : str + 'bi-dash-square-dotted';
                        };
                        
                        return (
                        <li key={item.order} className={`list-group-item border-0 ${ isActive ? 'bg-DEEP_SKY_BLUE text-white' : 'bg-white' }`}>
                            <button className={`border-0 text-start p-1 ${ isActive ? 'bg-DEEP_SKY_BLUE text-white' : 'bg-white' }`} 
                            onClick={(event)=>{
                                props.videoLoader(props.code,item.order) 
                                props.setVideoOrder(item.order)
                            }}>
                                <div className="d-flex">
                                    <div className="mx-1 my-auto">
                                        <i className={checkStatus() }> </i>
                                    </div>
                                    <div className="mx-1 p-1 text-wrap">
                                    {item.name}
                                        <div className={`text-secondary w-100 ${ isActive ? ' text-white' : '' }`} >
                                            <i className="bi bi-play-btn"></i> {item.duration}
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </li>
                        )
                    } )
                }
            </ul>
       
    )
}