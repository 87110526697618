import { Outlet } from "react-router-dom";

import TopbarMain from "../../components/TopbarMain";
import Sidebar from "../../components/Sidebar"


export default function StudentHome(props) {

    const menuItems = [
        { id: 1, name: "Dashboard", link: "/s/", icon: "house-door" },
        { id: 2, name: "My Courses", link: "courses", icon: "journal-text" },
        //{ id:3, name:"Performance", link:"performance", icon:"graph-up-arrow" },
        //{ id:4, name:"Calendar", link:"calendar", icon:"calendar3" },
        //{ id:5, name:"Messages", link:"messages", icon:"envelope" },
    ]

    const profiler={logout : props.logout, dpUrl: props.student.profile.dp}

    return (
        <div className="d-flex">
            <div id="sidebar" className="offcanvas-md flex-shrink-0" style={{width:"250px"}}>
                <Sidebar menuItems={menuItems}  profiler={profiler} bg="bg-theme"/>
            </div>
            <div className="flex-fill">
                <TopbarMain studentName={props.student.firstName} 
                studentTitle={props.student.profile.title} profiler={profiler}>
                    
                </TopbarMain>
                <div className="container">
                    <Outlet context={[props.student, props.setStudent, props.token]} />
                </div>
                
            </div>
        </div>
    )
}