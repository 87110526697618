import '../../../components/UI/styles.css';
import { courseThumbnail } from '../../../assets/MediaImports';
import FormCourseLecture from './FormCourseLecture';
import useUploader from '../../../hooks/useUploader';
import { API_BASE_URL } from '../../../APIs/APIConfig';

export default function FormCourse({ submitHandler, formData, setFormData, token }) {

    const star = <span style={{ color: "red" }}>*</span>

    const { media, setMedia, handleUpload } = useUploader()


    function handleThumbnailChange(e) {
        const file = e.target.files[0];

        if (file && file.type.startsWith('image/')) {
            setMedia(file);
            setFormData({ ...formData, thumbnail: 'https://storage.googleapis.com/cdn-lms-dev.appspot.com/thumbnails/' + file.name })
        }
    }
    function handleThumbnailClick() {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    }

    const handleAddLecture = (index) => {
        const updatedLectures = [...formData.lectures, {}];
        setFormData({
            ...formData,
            lectures: updatedLectures
        });
    };

    const handleDeleteLecture = (index) => {
        const updatedLectures = formData.lectures.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            lectures: [...updatedLectures]
        });
    };

    const thumbnailUploadHandler = () => {
        const url = `${API_BASE_URL}/upload/thumbnail/${formData.code}`
        handleUpload(url, token)
    }

    return (
        <>
            <form onSubmit={(event)=>{
                thumbnailUploadHandler();
                submitHandler(event);
            }}>
                <div className="fs-4 text-secondary mt-4"><i className="bi bi-journal-bookmark-fill"></i> New Course Info</div>
                <div className="container card shadow p-3 mb-2">
                    <div className="d-flex mb-4">
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Course Code</label>
                            <input placeholder="Enter Course Code" className="form-control" required
                                onChange={event => setFormData({ ...formData, code: event.target.value })} />
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Course Name</label>
                            <input placeholder="Enter Course Name" className="form-control" required
                                onChange={event => setFormData({ ...formData, name: event.target.value })}
                            />
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Teacher Name</label>
                            <input placeholder="Enter Teacher Name" className="form-control" required
                                onChange={event => setFormData({ ...formData, tutor: event.target.value })}
                            />
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Material Status</label>
                            <select className="form-control" onChange={event => setFormData({ ...formData, materialStatus: event.target.value })}>
                                <option value="true" >Available</option>
                                <option value="false" >Not Available</option>
                            </select>
                        </div>
                    </div>

                    <div className="d-flex mb-4">
                        <div className="flex-fill p-1">
                            <p className='fs-6'>Set Course Thumbnail</p>
                            <input type="file" id="fileInput" style={{ display: "none" }} onChange={handleThumbnailChange} accept="image/*" />
                            <label htmlFor="fileInput">
                                <button type="button" className='btn' style={{ width: "fit-content", padding: "0px" }} onClick={handleThumbnailClick}>
                                    <img src={media ? URL.createObjectURL(media) : courseThumbnail} alt='Course Thumbnail' style={{ height: "150px", width: "auto" }} />
                                </button>
                            </label>
                        </div>
                        <div className="flex-fill p-1 w-75">
                            <p className='fs-6'> Course Description</p>
                            <textarea style={{ width: "100%", height: "150px", padding: "8px" }} className='form-control'
                                onChange={event => setFormData({ ...formData, description: event.target.value })}></textarea>
                        </div>

                    </div>

                    {formData.lectures.map((lectureData, index) => (
                        <div key={index}>
                            <FormCourseLecture token={token}
                                courseCode={formData.code}
                                order={index + 1}
                                onDelete={(event) => { event.preventDefault(); handleDeleteLecture(index); }}
                                lectureData={lectureData}
                                setLectureData={(newData) => {
                                    const newDataList = [...formData.lectures];
                                    newDataList[index] = newData;
                                    setFormData({ ...formData, lectures: newDataList });
                                }}
                            />
                        </div>
                    ))}
                    <div className="d-flex mb-4">
                        <div className="w-25">
                            <button className="btn btn-secondary bg-NAVY" type="button" onClick={(event) => { event.preventDefault(); handleAddLecture(); }}>
                                <i className="bi bi-plus-lg"></i> Add Lecture</button>
                        </div>
                    </div>

                </div>

                <div className='mt-5 mb-5' style={{ width: "50%", margin: "auto" }}>
                    <button type="submit" className='btn btn-success w-100'>Submit</button>
                </div>
            </form >
        </>
    )
}