import { CircularProgress } from '@chakra-ui/react';
import {DEEP_SKY_BLUE} from './UI/colors';


export default function LoadingCircle({color}){
    
    return (
        <div className='d-flex flex-column justify-content-center align-items-center' style={{height:'100vh'}}>
        <CircularProgress isIndeterminate capIsRound color={color || DEEP_SKY_BLUE} size='100px' />
        </div>
    )
}