import { useState } from "react";
import './UI/styles.css';
import LoadingCircle from './LoadingCircle';
import { NAVY } from "./UI/colors";

export default function CustomTable(props) {

    const [filters, setFilters] = useState({});

    const columns = props.records.length!==0 ? Object.keys(props.records[0]) : []

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = props.recordsPerPage || 10 ;

    const handleFilterChange = (e, columnName) => {
        const { value } = e.target;
        setFilters((prevFilters) => ({ ...prevFilters, [columnName]: value }));
        setCurrentPage(1)
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredData = props.records.filter((item) =>
        Object.keys(filters).every((columnName) =>
            item[columnName].toString().toLowerCase().includes(filters[columnName].toLowerCase())
        )
    );

    const totalRecords = filteredData.length;
    const lastPageIndex = Math.ceil(totalRecords / recordsPerPage);
    const displayedData = filteredData.slice(
        (currentPage - 1) * recordsPerPage,
        currentPage * recordsPerPage
    );

    const convertToCSV = (data) => {
        const csvRows = [];
        const headers = columns;

        csvRows.push(headers.join(','));

        for (const row of data) {
            const values = headers.map((header) => row[header]);
            csvRows.push(values.join(','));
        }

        return csvRows.join('\n');
    };

    const handleExportCSV = () => {
        const csvData = convertToCSV(filteredData);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = props.title.toLowerCase().replace(' ','_') + '.csv';
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <div className="card shadow bg-white w-100 p-3 text-secondary text-center mt-3">
            <div className="d-flex">
                <h4><i className="bi bi-journal-text"></i> {props.title}</h4>
                <div className="ms-auto" data-toggle="tooltip" data-placement="top" title="Download CSV">
                    <button className="btn btn-white" onClick={handleExportCSV}>
                        <i className="bi bi-download text-primary"></i>
                    </button>
                </div>
            </div>
            { props.records.length===0 && <LoadingCircle color={NAVY} /> }
            { props.records.length!==0 &&
            <div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <td key={index}>
                                    <input type="text" value={filters[column] || ''} className="form-control"
                                    onChange={(e) => handleFilterChange(e, column)} placeholder={`${column}`} />
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {displayedData.map((item, index) => (
                            <tr key={index}>
                                {columns.map((column) => (
                                    <td key={`${index}-${column}`} className="text-start">
                                        <span className={`${column==='marked' && item[column]==='P' ? 'badge bg-success' : column==='marked' && item[column]==='A' ? 'badge bg-danger' : ''}`}>{item[column]}</span> 
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="">
                    <ul className="pagination pagination-lg">
                        {Array.from({ length: lastPageIndex }).map((_, index) => (
                            <li key={index} ><button onClick={() => handlePageChange(index + 1)} className={currentPage === index + 1 ? 'active' : ''}>
                                {index + 1}
                            </button></li>
                        ))}
                    </ul>
                </div>
            </div>}
        </div>
    )
}