import { useState } from "react"
import Tabs from "../../components/Tabs"
import AddCourses from "./components/AddCourses"
import DeleteCourses from "./components/DeleteCourses"
import ListCourses from "./components/ListCourses"


export default function AdminCourses() {
    const tabs = ['List', 'Add', 'Delete']

    const [display, setDisplay] = useState(<ListCourses />)

    const setOp = (op)=>{

        if(op==='List'){
            setDisplay(<ListCourses />)
        }
        if(op==='Add'){
            setDisplay(<AddCourses />)
        }  
        if(op==='Delete'){
            setDisplay(<DeleteCourses />)
        }  
    }

    return (
        <>
            <Tabs list={tabs} setOp={setOp} />
            <div className="container">
                {display}
            </div>
        </>
    )
}