import ReactPlayer from "react-player"

import LoadingCircle from "../../components/LoadingCircle";
import { useState } from "react";

export default function CourseVideo(props){

    const [duration, setDuration] = useState(null)

    if (props.videoUrl) {
        /*
        light={<img src={props.light} alt='Thumbnail' className="w-100"/>}
                config={{
                    file: {
                        attributes: {
                            onContextMenu: e => e.preventDefault(),
                            controlsList: 'nodownload'
                        }
                    }
                }} */

        return (
            <ReactPlayer url={props.videoUrl} className='w-100 h-100' playing={false} controls
                
                onProgress={(state) => {
                    const videoProgress = (state.playedSeconds / duration) * 100
                    const isCompleted = videoProgress > 95
                    props.setVideoDone(isCompleted)
                  }}
                onDuration={(state)=>{
                    setDuration(state)
                }}
            />
        )
    }
    else{
        return(
            <div className='w-100 h-100'>
                <LoadingCircle />
            </div>
        )
    }
}