import { useState } from "react"

export default function FormStudentCourse({ courses, courseData, setCourseData, onDelete}){

    const star = <span style={{color:"red"}}>*</span>

    const [courseCode, setCourseCode] = useState('')//defaults.courseCode || null)
    const [joinDate, setJoinDate] = useState('')//defaults.joinDate || null)
    const [numberOfDays, setNumberOfDays] = useState('');
    const [endDate, setEndDate] = useState('')//defaults.endDate || null)
    const [materialProvisionStatus, setMaterialProvisionStatus] = useState('')//defaults.materialProvisionStatus || null)

    const handleCourseCodeChange = (event) => {
        setCourseCode(event.target.value);
        const newData = { 
            ...courseData, 
            code: event.target.value.split('_')[0], 
            name: event.target.value.split('_')[1].split('$:')[0], 
            thumbnail: event.target.value.split('$:')[1]
        };
        setCourseData(newData);
    };
    const handleJoinDateChange = (event) => {
        setJoinDate(event.target.value);
        const newData = { ...courseData, subscription:{...courseData.subscription, joinDate: event.target.value}};
        setCourseData(newData);
        calculateEndDate(joinDate,numberOfDays);
    };
    const calculateEndDate = (startDate, days) => {
        if (startDate && days) {
            const startDateObj = new Date(startDate);
            startDateObj.setDate(startDateObj.getDate() + days);
            const endDateString = startDateObj.toISOString().split('T')[0];
            setEndDate(endDateString)
            return endDateString
        } else {
            setEndDate("")
            return ""
        }
    };
    const handleNumberOfDaysChange = (event) => {
        setNumberOfDays(parseInt(event.target.value, 10));
        const newData = { ...courseData, subscription:{...courseData.subscription, numberOfDays: event.target.value, 
            endDate: calculateEndDate(joinDate, parseInt(event.target.value, 10))}};
        setCourseData(newData);
    };
    const calculateNumberOfDays = (startDate, endDate) => {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        const timeDifference = endDateObj.getTime() - startDateObj.getTime();
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        setNumberOfDays(daysDifference);
        return daysDifference
    };
    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
        const newData = { ...courseData, subscription:{...courseData.subscription, numberOfDays: calculateNumberOfDays(joinDate, event.target.value) , endDate: event.target.value }};
        setCourseData(newData);
    };
    const handleMaterialProvisionStatusChange = (event) => {
        setMaterialProvisionStatus(event.target.value);
        const newData = { ...courseData, materialProvisionStatus: event.target.value };
        setCourseData(newData);
    };

    return (
        <>
            <div className="d-flex mb-4">
            <div className="me-4 my-auto">
                <button className="btn btn-danger" onClick={onDelete}><i className="bi bi-trash-fill"></i></button>
            </div>
                <div className="flex-fill p-1">
                    <label className="form-label">{star} Course</label>
                    <select className="form-control" required value={courseCode} onChange={handleCourseCodeChange}>
                        <option>select</option>
                        {courses.map((course, index)=>{
                            return <option key={index} value={course.code + '_' + course.name + '$:' + course.thumbnail}>{course.code} - {course.name}</option>
                        })}
                    </select>
                </div>
                <div className="flex-fill p-1">
                    <label className="form-label">{star} Join Date</label>
                    <input type="date" placeholder="Enter Middlename" className="form-control" required value={joinDate} onChange={handleJoinDateChange}/>
                </div>
                <div className="flex-fill p-1">
                    <label className="form-label">{star} No. of Days</label>
                    <input type="number" min={1} placeholder="Enter Number of Days" className="form-control" required value={numberOfDays} onChange={handleNumberOfDaysChange}/>
                </div>
                <div className="flex-fill p-1">
                    <label className="form-label"> End Date </label>
                    <input type="date" placeholder="Enter Lastname" className="form-control" required value={endDate} onChange={handleEndDateChange}/>
                </div>
                <div className="flex-fill p-1">
                    <label className="form-label"> Material Provision </label>
                    <select className="form-control" value={materialProvisionStatus} onChange={handleMaterialProvisionStatusChange}>
                        <option value="false" >Not Issued</option>
                        <option value="true" >Issued</option>
                    </select>
                </div>
            </div>
        </>
    )
}