import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const userToken = sessionStorage.getItem('access_token');
    return userToken && userToken;
  }


  const [isSessioned, setIsSessioned] = useState(false);

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken, userId=null) => {
    setToken(userToken)
    sessionStorage.setItem('access_token', userToken);
    setIsSessioned(true)
  };

  const removeToken = () => {
    setToken(null);
    sessionStorage.removeItem('access_token');
    setIsSessioned(false)
  }


  return {
    token,
    saveToken,
    removeToken,
    isSessioned,
    setIsSessioned
  };
}
