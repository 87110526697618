import '../../../components/UI/styles.css';
import FormStudentCourse from './FormStudentCourse';

export default function FormStudent({submitHandler, formData, setFormData, courses}) {

    const star = <span style={{ color: "red" }}>*</span>

    const handleAddCourse = (event) => {
        event.preventDefault()
        setFormData({...formData, program:{...formData.program, courses:[...formData.program.courses, {materialProvisionStatus:false, subscription:{}, lectureStatus:[]}] }});
            //(prevDataList) => [...prevDataList, {}]);
    };

    const handleDeleteCourse = (index) => {
        const updatedCourses = formData.program.courses.filter((_, i) => i !== index);
        setFormData({
            ...formData,
            program: {
                ...formData.program,
                courses: updatedCourses
            }
        });
    };
    

    return (
        <>
            <form onSubmit={submitHandler}>
                <div className="fs-4 text-secondary mt-4"><i className="bi bi-person"></i> Student Main Info</div>
                <div className="container card shadow p-3 mb-2">
                    <div className="d-flex mb-4">
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Firstname</label>
                            <input placeholder="Enter Firstname" className="form-control" required 
                            value={formData.firstName} onChange={event=> setFormData({...formData, firstName:event.target.value})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">Middlename</label>
                            <input placeholder="Enter Middlename" className="form-control" 
                            value={formData.middleName} onChange={event=> setFormData({...formData, middleName:event.target.value})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Lastname</label>
                            <input placeholder="Enter Lastname" className="form-control" required 
                            value={formData.lastName} onChange={event=> setFormData({...formData, lastName:event.target.value})}/>
                        </div>
                    </div>

                    <div className="d-flex mb-4">
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Student Id</label>
                            <input placeholder="Student Id" type="number" className="form-control" title="this is auto generated by the system" 
                            value={formData.id} onChange={event=> setFormData({...formData, id: parseInt(event.target.value)})} required/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Email</label>
                            <input placeholder="Enter email" type="email" className="form-control" required 
                            value={formData.email} onChange={event=> setFormData({...formData, email:event.target.value})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Phone Number</label>
                            <input placeholder="Enter Phone" type="number" className="form-control" required minLength={10} maxLength={10}
                            value={formData.phone} onChange={event=> setFormData({...formData, phone:event.target.value})}/>
                        </div>
                    </div>
                </div>

                <div className="fs-4 text-secondary mt-4"><i className="bi bi-person-vcard"></i> Student Profile Info</div>
                <div className="container card shadow p-3 mb-2">

                    <div className="d-flex mb-4">
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Title</label>
                            <select className='form-control' 
                            onChange={event=> setFormData({...formData, profile:{...formData.profile, title: event.target.value}})}>
                                <option value="Mr." >Mr.</option>
                                <option value="Ms." >Ms.</option>
                                <option value="Mrs." >Mrs.</option>
                                <option value="Sri." >Sri.</option>
                                <option value="Smt." >Smt.</option>
                            </select>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Gender</label>
                            <select className='form-control' 
                            onChange={event=> setFormData({...formData, profile:{...formData.profile, gender: event.target.value}})}>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="others">Others</option>
                            </select>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label"> Aadhaar No.</label>
                            <input placeholder="Enter Aadhaar No." type="number" className="form-control"  minLength={12} maxLength={12}
                            value={formData.profile.aadhaar} onChange={event=> setFormData({...formData, profile:{...formData.profile, aadhaar: event.target.value}})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label"> DOB</label>
                            <input type="date" className="form-control"  
                            value={formData.profile.dob} onChange={event=> setFormData({...formData, profile:{...formData.profile, dob: event.target.value}})}/>
                        </div>
                    </div>

                    <div className="d-flex mb-4">
                    <div className="flex-fill p-1">
                            <label className="form-label">{star} Student Education / Profession</label>
                            <input placeholder="Enter Student Education / Profession" className="form-control" required 
                            value={formData.profile.profession} onChange={event=> setFormData({...formData, profile:{...formData.profile, profession: event.target.value}})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label"> Father Name</label>
                            <input placeholder="Enter Father Name" className="form-control"  
                            value={formData.profile.fatherName} onChange={event=> setFormData({...formData, profile:{...formData.profile, fatherName: event.target.value}})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">Father Occupation</label>
                            <input placeholder="Enter Father Occupation" className="form-control"
                            value={formData.profile.fatherOccupation} onChange={event=> setFormData({...formData, profile:{...formData.profile, fatherOccupation: event.target.value}})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label"> Alternate Phone Number</label>
                            <input placeholder="Enter Alternate Phone number" type="number" className="form-control"  minLength={10} maxLength={10}
                            value={formData.profile.alternatePhone} onChange={event=> setFormData({...formData, profile:{...formData.profile, alternatePhone: event.target.value}})}/>
                        </div>
                    </div>
                    <div className='d-flex mb-4'>
                        <div className="flex-fill p-1">
                            <label className="form-label"> Reference through</label>
                            <input placeholder="Enter Reference through" className="form-control" 
                            value={formData.profile.reference} onChange={event=> setFormData({...formData, profile:{...formData.profile, reference:event.target.value}})}/>
                        </div>
                        <div className="flex-fill p-1">&nbsp;</div>
                        <div className="flex-fill p-1">&nbsp;</div>
                        <div className="flex-fill p-1">&nbsp;</div>
                    </div>

                    <h5 className="text-secondary">Address Details</h5>
                    <div className="d-flex mb-4">
                        <div className="flex-fill p-1">
                            <label className="form-label">D.No.</label>
                            <input placeholder="Enter Door No." className="form-control" 
                            value={formData.profile.address.dno} onChange={event=> setFormData({...formData, profile:{...formData.profile, address: {...formData.profile.address, dno:event.target.value}}})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">Street</label>
                            <input placeholder="Enter Street" className="form-control"  
                            value={formData.profile.address.street} onChange={event=> setFormData({...formData, profile:{...formData.profile, address: {...formData.profile.address, street:event.target.value}}})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">Locality / Area</label>
                            <input placeholder="Enter Locality / Area" className="form-control"   
                            value={formData.profile.address.locality} onChange={event=> setFormData({...formData, profile:{...formData.profile, address: {...formData.profile.address, locality:event.target.value}}})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">Landmark</label>
                            <input placeholder="Enter Landmark" className="form-control"   
                            value={formData.profile.address.landmark} onChange={event=> setFormData({...formData, profile:{...formData.profile, address: {...formData.profile.address, landmark:event.target.value}}})}/>
                        </div>
                    </div>

                    <div className="d-flex mb-4">
                        <div className="flex-fill p-1">
                            <label className="form-label">State</label>
                            <input placeholder="Enter State" className="form-control"  
                            value={formData.profile.address.state} onChange={event=> setFormData({...formData, profile:{...formData.profile, address: {...formData.profile.address, state:event.target.value}}})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">City</label>
                            <input placeholder="Enter City" className="form-control" required  
                            value={formData.profile.address.city} onChange={event=> setFormData({...formData, profile:{...formData.profile, address: {...formData.profile.address, city:event.target.value}}})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">Pin Code</label>
                            <input placeholder="Enter Pin Code" type="number" className="form-control" required  
                            value={formData.profile.address.pinCode} onChange={event=> setFormData({...formData, profile:{...formData.profile, address: {...formData.profile.address, pinCode:event.target.value}}})}/>
                        </div>
                    </div>
                </div>

                <div className="fs-4 text-secondary mt-4"><i className="bi bi-journal-code"></i> Student Program Info</div>
                <div className="container card shadow p-3 mb-2">

                    <div className="d-flex mb-4">
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Program Name</label>
                            <input placeholder="Enter Program Name" className="form-control" required 
                            value={formData.program.name} onChange={event=> setFormData({...formData, program:{...formData.program, name:event.target.value}})}/>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">{star} Program Type</label>
                            <select className='form-control'
                             onChange={event=> setFormData({...formData, program:{...formData.program, type: event.target.value}})}>
                                <option value="Regular">Regular</option>
                                <option value="Internship">Internship</option>
                                <option value="Apprenticeship">Apprenticeship</option>
                                <option value="Employee Training">Employee Training</option>
                            </select>
                        </div>
                        <div className="flex-fill p-1">
                            <label className="form-label">Duration </label>
                            <input placeholder="Enter Duration" className="form-control"  
                            value={formData.program.duration} onChange={event=> setFormData({...formData, program:{...formData.program, duration:event.target.value}})}/>
                        </div>
                    </div>

                    <div className="d-flex mb-4">
                        <div className="flex-fill p-1">
                            <button placeholder="Enter Firstname" className="btn btn-secondary bg-NAVY w-25"
                                onClick={handleAddCourse}> + Add course</button>
                        </div>
                    </div>

                    {formData.program.courses.map((courseData, index) => (
                        <div key={index}>
                            <FormStudentCourse courses={courses}
                                courseData={courseData}
                                setCourseData={(newData) => {
                                    const newDataList = [...formData.program.courses];
                                    newDataList[index] = newData;
                                    //setCourseDataList(newDataList);
                                    setFormData({...formData, program:{...formData.program, courses:newDataList }});
                                }}
                                onDelete={() => handleDeleteCourse(index)}
                            />
                        </div>
                    ))}
                </div>
                <div className='mt-5 mb-5' style={{width:"50%", margin:"auto"}}>
                    <button type="submit" className='btn btn-success w-100'>Submit</button>
                </div>
            </form>
        </>
    )
}